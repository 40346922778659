import * as React from "react";
import { graphql, PageProps } from "gatsby";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";
import { DexExchangesQuery } from "../../../graphql-types";
import { NavTab } from "../../components/layout/navigation/navigation";
import Exchanges from "../../components/pages/exchanges/exchanges";

type DexExchangesPageProps = PageProps<DexExchangesQuery>;

const DexExchangesPage: React.FC<DexExchangesPageProps> = ({ data }) => {
  return (
    <Layout activeTab={NavTab.Exchanges}>
      <SEO
        title="Top Decentralized Exchanges(DEX)"
        description="Cryptocurrency Decentralized Exchanges(DEX), volume and trust score. Uniswap, Sushiswap, 1inch, Balancer, And many more"
      />
      <Exchanges isDex={true} staticData={data} />
    </Layout>
  );
};

export default DexExchangesPage;

export const query = graphql`
  query DexExchanges {
    allExchangeListItem(
      filter: { is_dex: { eq: true } }
      sort: { fields: [trade_volume_24h_btc], order: [DESC] }
    ) {
      nodes {
        id
        slug
        name
        image
        trade_volume_24h_btc
      }
    }
  }
`;
